import React from 'react';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import { isAMV2Enabled } from 'components/template-management/utilities';
import Templates from '.';

/**
 * Loads templates and shows a loader in the meantime before openeing the component
 * @param {*} WrappedComponent
 */
export default function withCreativeTemplates(WrappedComponent) {
    class TemplateWrapperHolder extends React.Component {
        constructor(props) {
            super(props);
            this.state = { loading: isAMV2Enabled() ? false : true };
        }

        componentDidMount() {
            if (isAMV2Enabled()) return;
            this.loadTemplates();
        }

        loadTemplates = async () => {
            await Templates.asyncGetTemplatesList([
                'staticAsset',
                'dynamicVideo',
                'dynamicImage',
                'dynamicImageDesigned',
                'dynamicVideoDesigned',
                'dynamicAfterEffects',
                'dynamicInDesign',
                'dynamicPDFDesigned',
                'dynamicPDF',
                'displayAd',
                'displayAdDesigned',
                'socialChannelItem'
            ]);
            this.setState({ loading: false });
        };

        render() {
            const { loading } = this.state;
            if (loading > 0) {
                return (
                    <div style={{ justifyContent: 'center', display: 'flex', width: '100%', padding: 16 }}>
                        <CircularProgress />
                    </div>
                );
            } else {
                return <WrappedComponent {...this.props} />;
            }
        }
    }
    return TemplateWrapperHolder;
}
