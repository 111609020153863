import AssetManagementService from 'components/asset-management/services/asset-management.service';
import { isAMV2Enabled } from 'components/template-management/utilities';
import Request from 'components/data/Request';
import { SOCIAL_CHANNEL_ITEMS } from 'components/template-management/constants';
import { STATIC_ASSETS } from 'components/template-management/constants';
import DataHelpers from '../../DataHelpers';
import { batchConvertAssetV2toTemplate } from '../helpers/template-converter';

export default class Templates {
    static templateData = Templates.initializeTemplateData();
    static initializeTemplateData() {
        const templateData = {};
        // Load the social channel items as if they were templates so they can be referenced in Creative Builder
        SOCIAL_CHANNEL_ITEMS.forEach((item) => {
            if (item.identifier) {
                if (!templateData['socialChannelItem']) {
                    templateData['socialChannelItem'] = {};
                }
                templateData['socialChannelItem'][item.identifier] = item;
            }
        });
        // Load the static assets items as if they were templates so they can be referenced in Creative Builder
        STATIC_ASSETS.forEach((item) => {
            if (item.identifier) {
                if (!templateData['staticAsset']) {
                    templateData['staticAsset'] = {};
                }
                templateData['staticAsset'][item.identifier] = item;
            }
        });
        return templateData;
    }

    static loaded = Templates.initializeLoaded();
    static initializeLoaded() {
        const loaded = {};
        SOCIAL_CHANNEL_ITEMS.forEach((item) => {
            if (item.identifier) {
                loaded['socialChannelItem_' + item.identifier + '_summary'] = true;
                loaded['socialChannelItem_' + item.identifier + '_full'] = true;
            }
        });
        STATIC_ASSETS.forEach((item) => {
            if (item.identifier) {
                loaded['staticAsset_' + item.identifier + '_summary'] = true;
                loaded['staticAsset_' + item.identifier + '_full'] = true;
            }
        });
        return loaded;
    }

    /**
     * Get previously loaded item
     * @param templateType The template type
     * @param {*} identifier The identifier of the template (optional)
     * @param path The data model to use (optional)
     * @returns The result data as an object
     */
    static get(templateType, identifier = false, model = false) {
        if (!templateType) {
            return false;
        }
        if (!identifier) {
            return Templates.templateData[templateType];
        } else if (Templates.templateData[templateType] && Templates.templateData[templateType][identifier] && !model) {
            return Templates.templateData[templateType][identifier];
        } else if (Templates.templateData[templateType] && Templates.templateData[templateType][identifier]) {
            return DataHelpers.getValue(Templates.templateData[templateType][identifier], model);
        } else {
            return false;
        }
    }

    /**
     * Update template in local storage
     * @param {*} templateType
     * @param {*} identifier
     * @param {*} data
     */
    static set(templateType, identifier, data) {
        if (!Templates.templateData[templateType]) {
            Templates.templateData[templateType] = {};
        }
        if (!Templates.templateData[templateType][identifier]) {
            Templates.templateData[templateType][identifier] = {};
        }

        const existingData = Templates.templateData[templateType][identifier];
        Templates.templateData[templateType][identifier] = { ...existingData, ...data };
    }

    /**
     * Get previously loaded item
     * @param template The main template set
     * @param subItem The subitem
     */
    static reset() {
        Templates.templateData = Templates.initializeTemplateData();
        Templates.loaded = Templates.initializeLoaded();
    }

    /**
     * Get individual template with full data
     * @param {*} type the type of template
     * @param {*} fullData whether we want to load the full data
     * @returns
     */
    static async asyncGetTemplatesItem(type, identifier) {
        await Templates.asyncGetTemplatesList([{ type, identifier, fullData: true }]);
        return Templates.get(type, identifier);
    }

    /**
     * Get list of templates with needed data
     * The input data is an array of format:
     * [{
     * type: 'emailBlock',
     * identifier: 'header',
     * fullData: true
     * }]
     *
     * When you leave the identifier empty, we return all with this type
     * @param {array} types
     */
    static async asyncGetTemplatesList(types) {
        const typesToLoad = [];

        // Check what to load
        types.forEach((type) => {
            if (typeof type === 'string') {
                type = { type, fullData: false };
            }
            const findKey = type.type + '_' + type.identifier + '_' + (type.fullData ? 'full' : 'summary');
            if (!Templates.loaded[findKey]) {
                typesToLoad.push(type);
            }
        });

        if (typesToLoad.length === 0) {
            return;
        }

        let data = null;

        // Load the data from remote host
        if (isAMV2Enabled()) {
            const assetData = await Templates.getAllTemplates(typesToLoad);
            data = { data: batchConvertAssetV2toTemplate(assetData) };
        } else {
            data = await Request.post('templates/getItemList', { types: typesToLoad });
        }

        typesToLoad.forEach((type) => {
            const findKey = type.type + '_' + type.identifier + '_' + (type.fullData ? 'full' : 'summary');
            Templates.loaded[findKey] = true;
        });

        // Merge data
        if (data.data) {
            data.data.forEach((item) => {
                // Create type if it doesn't exist
                if (!Templates.templateData[item.type]) {
                    Templates.templateData[item.type] = {};
                }

                const findKey = item.type + '_' + item.identifier + '_' + (item.fullData ? 'full' : 'summary');
                Templates.loaded[findKey] = true;

                // In case the key already exists, don't overwrite
                if (!Templates.templateData[item.type][item.identifier] || item.fullData) {
                    Templates.templateData[item.type][item.identifier] = item;
                }
            });
        }
    }

    static async getAllTemplates(typesToLoad) {
        let allTemplates = [];

        const typesWithFullData = typesToLoad.filter((type) => type.fullData && !type.identifier);
        const typesWithSummaryData = typesToLoad.filter((type) => !type.fullData && !type.identifier);
        const typesWithSpecificTemplate = typesToLoad.filter((type) => type.identifier);

        // Load all templates with summary data
        if (typesWithSummaryData.length > 0) allTemplates = [...allTemplates, ...(await Templates.getTemplates(typesWithSummaryData, false))];

        // Load all templates with full data
        if (typesWithFullData.length > 0) allTemplates = [...allTemplates, ...(await Templates.getTemplates(typesWithFullData, true))];

        // Load all templates with specific identifier
        for (const type of typesWithSpecificTemplate) {
            const template = await Templates.getTemplate(type.identifier, type.fullData);
            allTemplates = [...allTemplates, ...template];
        }

        return allTemplates;
    }

    static async getTemplate(identifier, fullData = false) {
        const response = await AssetManagementService.getAssetBatch(
            {
                type: 'template',
                filters: {
                    status: ['available']
                },
                dataFilters: {
                    identifier
                },
                filterReleased: true
            },
            {
                isVerbose: fullData
            }
        );

        return response.assets.map((item) => {
            return { ...item, fullData };
        });
    }

    static async getTemplates(typesToLoad, fullData = false) {
        let allTemplates = [];
        let nextPageToken = 0;
        let firstRun = true;

        while ((firstRun && nextPageToken === 0) || nextPageToken) {
            if (firstRun) firstRun = false;
            const response = await AssetManagementService.getAssetBatch(
                {
                    type: 'template',
                    filters: {
                        status: ['available'],
                        subType: typesToLoad.map((type) => type.type)
                    },
                    nextPageToken: nextPageToken,
                    pageLimit: 300,
                    filterReleased: true
                },
                {
                    isVerbose: fullData
                }
            );

            // Add the templates from the current page to the result array
            allTemplates = allTemplates.concat(response.assets);

            // Update the nextPageToken for the next iteration
            nextPageToken = response.nextPageToken;
        }

        return allTemplates.map((item) => {
            return { ...item, fullData };
        });
    }
}
